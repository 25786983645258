import React from "react"
import Layout from "../components/_App/layout"
import Seo from "../components/_App/seo"
import Navbar from "../components/_App/Navbar"
import Footer from "../components/_App/Footer"
import PageBanner from "../components/Common/PageBanner"
import FeedbackStyleFour from "../components/Common/FeedbackStyleFour";
import ClutchTestimonials from "../components/Common/ClutchTestimonials";

const Testimonials = () => (
  <Layout>
    <Seo title="Testimonials" />

    <Navbar />

    {/*<PageBanner pageTitle="Testimonials" />*/}

    {/*<FeedbackStyleThree />*/}

    {/*<FeedbackStyleTwo />*/}

    <FeedbackStyleFour />
      {/*<ClutchTestimonials />*/}

    {/*<FeedbackStyleFive />*/}

    <Footer />
  </Layout>
)

export default Testimonials
